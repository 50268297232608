import { FormEvent, useEffect, useMemo, useState, useContext } from "react";
import { useBoolean } from "@fluentui/react-hooks"
import { Checkbox, DefaultButton, Dialog, FontIcon, Stack, Text } from "@fluentui/react";
import DOMPurify from 'dompurify';
import { AppStateContext } from '../../state/AppProvider';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { CopyRegular, DeleteRegular } from "@fluentui/react-icons";

import styles from "./Answer.module.css";

import { AskResponse, Citation, Feedback, historyMessageFeedback, generateEML, removeMessage } from "../../api";
import { parseAnswer } from "./AnswerParser";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from 'remark-supersub'
import { ThumbDislike20Filled, ThumbLike20Filled } from "@fluentui/react-icons";
import { XSSAllowTags } from "../../constants/xssAllowTags";

interface Props {
    answer: AskResponse;
    onCitationClicked: (citedDocument: Citation) => void;
}

export const Answer = ({
    answer,
    onCitationClicked
}: Props) => {
    const initializeAnswerFeedback = (answer: AskResponse) => {
        if (answer.message_id == undefined) return undefined;
        if (answer.feedback == undefined) return undefined;
        if (answer.feedback.split(",").length > 1) return Feedback.Negative;
        if (Object.values(Feedback).includes(answer.feedback)) return answer.feedback;
        return Feedback.Neutral;
    }

    const [isRefAccordionOpen, { toggle: toggleIsRefAccordionOpen }] = useBoolean(false);
    const filePathTruncationLimit = 50;
    const [copyClicked, setCopyClicked] = useState<boolean>(false);
    const [copyText, setCopyText] = useState<string>("");
    const parsedAnswer = useMemo(() => parseAnswer(answer), [answer]);
    const [chevronIsExpanded, setChevronIsExpanded] = useState(isRefAccordionOpen);
    const [feedbackState, setFeedbackState] = useState(initializeAnswerFeedback(answer));
    const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);
    const [showReportInappropriateFeedback, setShowReportInappropriateFeedback] = useState(false);
    const [negativeFeedbackList, setNegativeFeedbackList] = useState<Feedback[]>([]);
    const [showOtherTextbox, setShowOtherTextbox] = useState(false);
    const [showEMLButton, setShowEMLButton] = useState(false);
    const appStateContext = useContext(AppStateContext)
    const FEEDBACK_ENABLED = appStateContext?.state.frontendSettings?.feedback_enabled && appStateContext?.state.isCosmosDBAvailable?.cosmosDB; 
    const SANITIZE_ANSWER = appStateContext?.state.frontendSettings?.sanitize_answer 
    
    const handleChevronClick = () => {
        setChevronIsExpanded(!chevronIsExpanded);
        toggleIsRefAccordionOpen();
      };

    useEffect(() => {
        setChevronIsExpanded(isRefAccordionOpen);
    }, [isRefAccordionOpen]);

    useEffect(() => {
        // Check if the answer is an email or not
        //FIXME: ResolveIQ:646
        if (appStateContext?.state.frontendSettings?.dev_mode === false) return;
        if (answer.message_id == undefined) return;
        const element = document.getElementById(answer.message_id);
    
        if (element) {
          const content = element.textContent || '';
          const hasSubject = content.includes('Subject');
          const hasBody = content.includes('Body');
          
          setShowEMLButton(hasSubject && hasBody);
        }
      }, [appStateContext?.state.isGenerating]);

    useEffect(() => {
        if (answer.message_id == undefined) return;
        
        let currentFeedbackState;
        if (appStateContext?.state.feedbackState && appStateContext?.state.feedbackState[answer.message_id]) {
            currentFeedbackState = appStateContext?.state.feedbackState[answer.message_id];
        } else {
            currentFeedbackState = initializeAnswerFeedback(answer);
        }
        setFeedbackState(currentFeedbackState)
    }, [appStateContext?.state.feedbackState, feedbackState, answer.message_id]);

    const createCitationFilepath = (citation: Citation, index: number, truncate: boolean = false) => {
        let citationFilename = "";

        if (citation.title) {
            if (truncate && citation.title.length > filePathTruncationLimit) {
                citationFilename = `${citation.title.substring(0, 20)}... - Part ${index}`;
            }
            else {
                citationFilename = `${citation.title} - Part ${index}`;
            }
        }
        else if (citation.url) {
            const part_i = citation.part_index ?? (citation.chunk_id ? parseInt(citation.chunk_id) + 1 : '');
            if (truncate && citation.url.length > filePathTruncationLimit) {
                const citationLength = citation.url.length;
                citationFilename = `${citation.url.substring(0, 20)}...${citation.url.substring(citationLength - 20)} - Part ${part_i}`;
            }
            else {
                citationFilename = `${citation.url} - Part ${part_i}`;
            }
        }
        else if (citation.filepath) {
            const part_i = citation.part_index ?? (citation.chunk_id ? parseInt(citation.chunk_id) + 1 : '');
            if (truncate && citation.filepath.length > filePathTruncationLimit) {
                const citationLength = citation.filepath.length;
                citationFilename = `${citation.filepath.substring(0, 20)}...${citation.filepath.substring(citationLength - 20)} - Part ${part_i}`;
            }
            else {
                citationFilename = `${citation.filepath} - Part ${part_i}`;
            }
        }
        else if (citation.filepath && citation.reindex_id) {
            citationFilename = `${citation.filepath} - Part ${citation.reindex_id}`;
        }
        else {
            citationFilename = `Citation ${index}`;
        }
        return citationFilename;
    }

    const onLikeResponseClicked = async () => {
        if (answer.message_id == undefined) return;

        let newFeedbackState = feedbackState;
        // Set or unset the thumbs up state
        if (feedbackState == Feedback.Positive) {
            newFeedbackState = Feedback.Neutral;
        }
        else {
            newFeedbackState = Feedback.Positive;
        }
        appStateContext?.dispatch({ type: 'SET_FEEDBACK_STATE', payload: { answerId: answer.message_id, feedback: newFeedbackState } });
        setFeedbackState(newFeedbackState);

        // Update message feedback in db
        await historyMessageFeedback(answer.message_id, newFeedbackState);
    }

    const onDislikeResponseClicked = async () => {
        if (answer.message_id == undefined) return;

        let newFeedbackState = feedbackState;
        if (feedbackState === undefined || feedbackState === Feedback.Neutral || feedbackState === Feedback.Positive) {
            newFeedbackState = Feedback.Negative;
            setFeedbackState(newFeedbackState);
            setIsFeedbackDialogOpen(true);
        } else {
            // Reset negative feedback to neutral
            newFeedbackState = Feedback.Neutral;
            setFeedbackState(newFeedbackState);
            await historyMessageFeedback(answer.message_id, Feedback.Neutral);
        }
        appStateContext?.dispatch({ type: 'SET_FEEDBACK_STATE', payload: { answerId: answer.message_id, feedback: newFeedbackState }});
    }

    const updateFeedbackList = (ev?: FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        if ((ev?.target as HTMLInputElement)?.id === Feedback.OtherUnhelpful) {
            checked ? setShowOtherTextbox(true) : setShowOtherTextbox(false);
        }else if ((ev?.target as HTMLInputElement)?.id === Feedback.OtherHarmful) {
            checked ? setShowOtherTextbox(true) : setShowOtherTextbox(false);
        }
        
        if (answer.message_id == undefined) return;
        let selectedFeedback = (ev?.target as HTMLInputElement)?.id as Feedback;

        let feedbackList = negativeFeedbackList.slice();
        if (checked) {
            feedbackList.push(selectedFeedback);
        } else {
            feedbackList = feedbackList.filter((f) => f !== selectedFeedback);
        }

        setNegativeFeedbackList(feedbackList);
    };

    const onSubmitNegativeFeedback = async () => {
        if (answer.message_id == undefined) return;
        if (negativeFeedbackList.length < 1) return;
        let feedback = negativeFeedbackList.join(",");
        if (negativeFeedbackList.includes(Feedback.OtherUnhelpful) || negativeFeedbackList.includes(Feedback.OtherHarmful)) {
            feedback = feedback + ": " + (document.getElementById('other_content') as HTMLInputElement).value;
        }
        await historyMessageFeedback(answer.message_id, feedback);
        resetFeedbackDialog();
    }

    const resetFeedbackDialog = () => {
        setIsFeedbackDialogOpen(false);
        setShowReportInappropriateFeedback(false);
        setNegativeFeedbackList([]);
    }
    
    const downloadWord = () => {
        if (!answer.message_id) {
            console.log("No message ID found");
            return;
        }
        let url = generateWord();
        if (!url) {
            console.log("No Document URL Generated.");
            return;
        }
        let a = document.createElement('a');
        a.href = url;
        let title = appStateContext?.state.currentChat?.title;
        a.download = title + '.doc';
        a.click();
    }

    const uploadProcessDoc = () => {
        if (!answer.message_id) {
            console.log("No message ID found");
            return;
        }
        let url = generateWord();
        if (!url) {
            console.log("No Document URL Generated.");
            return;
        }
        // Send the URL to the server
        // The server will download the file and process it
        

    }

    const generateWord = () => {
        if (!answer.message_id) {
            console.log("No message ID found");
            return;
        }
        console.log("Generating Word for message ID: ", answer.message_id);
        const input = document.getElementById(answer.message_id); // Ensure your answer container has this ID
        if (!input) {
            return;
        }
        let title = appStateContext?.state.currentChat?.title;
        let html = input.innerHTML;
        let blob = new Blob(['<!DOCTYPE html><html><head><meta charset="UTF-8"><title>' + title + '</title></head><body>' + html + '</body></html>'], { type: 'application/msword' });
        let url = URL.createObjectURL(blob);
        console.log("URL:", url);
        return url;
    }

    const generateEmail = async () => {
        if (!answer.message_id) {
            console.log("No message ID found");
            return;
        }
        console.log("Generating EML for message ID: ", answer.message_id);
        const input = document.getElementById(answer.message_id); // Ensure your answer container has this ID
        if (!input) {
            return;
        }
        
        // Extract information
        const content = {"email":input.innerHTML};
        const filename = appStateContext?.state.currentChat?.title + '.eml';

        // Create MSG
        try {
            // Send a request to the server endpoint
            let response = await generateEML(JSON.stringify(content));
            
            if(response === null){
                return
            }

            // Assuming the response contains a property `fileName` with the file name
            const downloadFile = await response.text(); // Adjust this according to the actual response structure
            console.log("Download File:", downloadFile)
            // Construct the URL for the file
            const fileUrl = `${window.location.href}/${downloadFile}`;
            console.log("File URL:", fileUrl)
            // Create an anchor element and trigger download
            const downloadLink = document.createElement('a');
            downloadLink.href = fileUrl;
            downloadLink.setAttribute('download', filename); 
            document.body.appendChild(downloadLink); 
            downloadLink.click(); 
            document.body.removeChild(downloadLink); 


        } catch (error) {
            console.error('Error downloading EML file:', error);
        }
    }

    const handleCopyClick = () => {
        if (answer.message_id == undefined) return;
        const input = document.getElementById(answer.message_id);

        if (input) {
            const text = input.textContent;
            navigator.clipboard.writeText(text || '')
                .then(() => {
                    setCopyText("Copied!");
                    setCopyClicked(true);
                    setTimeout(() => {
                        setCopyText("");
                        setCopyClicked(false);
                    }, 2000);
                })
                .catch((err) => {
                    console.error('Failed to copy text to clipboard', err);
                });
        }
    }

    const handleDeleteClick = async () => {
        if (answer.message_id == undefined) return;
        
        const removed = await removeMessage(answer.message_id);
    
        if (removed === true) {
            console.log("Message removed");
            // Remove the message from the currentChat
            let currentChat = appStateContext?.state.currentChat;
            if (currentChat) {
                currentChat.messages = currentChat.messages.filter((m) => m.id !== answer.message_id);
                appStateContext?.dispatch({ type: 'UPDATE_CHAT_HISTORY', payload: currentChat });
            }
        } else {
            console.log("Message not removed");
            // Show an error message
            alert("Error removing message.");
        }
    }
    
    useEffect(() => {
        if (copyClicked) {
            setCopyText("Copied!");
        }
    }, [copyClicked]);

    const generatePDF = () => {
        if (!answer.message_id) {
            console.log("No message ID found");
            return;
        }
        console.log("Generating PDF for message ID: ", answer.message_id);
        const input = document.getElementById(answer.message_id); // Ensure your answer container has this ID
        if (!input) {
            return;
        }
        let title = appStateContext?.state.currentChat?.title;
        html2canvas(input, {scrollY: -window.scrollY})
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                    orientation: 'portrait',
                });
                const imgProps= pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = pdf.internal.pageSize.getHeight();
                const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;
                let heightLeft = imgHeight;

                let position = 0;

                pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
                heightLeft -= pdfHeight;

                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    pdf.addPage();
                    pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
                    heightLeft -= pdfHeight;
                }
                pdf.save(title + '.pdf');
            });
    };

    const UnhelpfulFeedbackContent = () => {
        
        return (
            <>
                <div>Why wasn't this response helpful?</div>
                <Stack tokens={{ childrenGap: 4 }}>
                    <Checkbox label="Citations are missing" id={Feedback.MissingCitation} defaultChecked={negativeFeedbackList.includes(Feedback.MissingCitation)} onChange={updateFeedbackList}></Checkbox>
                    <Checkbox label="Citations are wrong" id={Feedback.WrongCitation} defaultChecked={negativeFeedbackList.includes(Feedback.WrongCitation)} onChange={updateFeedbackList}></Checkbox>
                    <Checkbox label="The response is not from my data" id={Feedback.OutOfScope} defaultChecked={negativeFeedbackList.includes(Feedback.OutOfScope)} onChange={updateFeedbackList}></Checkbox>
                    <Checkbox label="Inaccurate or irrelevant" id={Feedback.InaccurateOrIrrelevant} defaultChecked={negativeFeedbackList.includes(Feedback.InaccurateOrIrrelevant)} onChange={updateFeedbackList}></Checkbox>
                    <Checkbox label="Other" id={Feedback.OtherUnhelpful} defaultChecked={negativeFeedbackList.includes(Feedback.OtherUnhelpful)} onChange={updateFeedbackList}></Checkbox>
                    {showOtherTextbox && <input id={Feedback.OtherContent} type="text" placeholder="Please specify" />}
                </Stack>
                <div onClick={() => setShowReportInappropriateFeedback(true)} style={{ color: "#115EA3", cursor: "pointer" }}>Report inappropriate content</div>
            </>
        );
    }

    const ReportInappropriateFeedbackContent = () => {

        return (
            <>
                <div>The content is <span style={{ color: "red" }} >*</span></div>
                <Stack tokens={{childrenGap: 4}}>
                    <Checkbox label="Hate speech, stereotyping, demeaning" id={Feedback.HateSpeech} defaultChecked={negativeFeedbackList.includes(Feedback.HateSpeech)} onChange={updateFeedbackList}></Checkbox>
                    <Checkbox label="Violent: glorification of violence, self-harm" id={Feedback.Violent} defaultChecked={negativeFeedbackList.includes(Feedback.Violent)} onChange={updateFeedbackList}></Checkbox>
                    <Checkbox label="Sexual: explicit content, grooming" id={Feedback.Sexual} defaultChecked={negativeFeedbackList.includes(Feedback.Sexual)} onChange={updateFeedbackList}></Checkbox>
                    <Checkbox label="Manipulative: devious, emotional, pushy, bullying" defaultChecked={negativeFeedbackList.includes(Feedback.Manipulative)} id={Feedback.Manipulative} onChange={updateFeedbackList}></Checkbox>
                    <Checkbox label="Other" id={Feedback.OtherHarmful} defaultChecked={negativeFeedbackList.includes(Feedback.OtherHarmful)} onChange={updateFeedbackList}></Checkbox>
                    {showOtherTextbox && <input id={Feedback.OtherContent} type="text" placeholder="Please specify" />}
                </Stack>
            </>
        );
    }

    return (
        <>
            <Stack className={styles.answerContainer} tabIndex={0}>
                {appStateContext?.state.isGenerating != true && <Stack.Item className={styles.flexContainer}>
                    <div
                        className={styles.copyButtonContainer}
                        role="button"
                        tabIndex={0}
                        aria-label="Copy"
                        onClick={handleCopyClick}
                        onKeyDown={e => e.key === "Enter" || e.key === " " ? handleCopyClick() : null}
                    >
                        <CopyRegular className={styles.copyButton} />
                        <span className={styles.copyButtonText}>{copyText}</span>
                    </div>
                    <div
                        className={styles.deleteButtonContainer}
                        role="button"
                        tabIndex={0}
                        aria-label="Delete"
                        onClick={handleDeleteClick}
                        onKeyDown={e => e.key === "Enter" || e.key === " " ? handleDeleteClick() : null}
                    >
                        <DeleteRegular className={styles.deleteButton} />
                    </div>
                </Stack.Item>}
                <Stack.Item>
                    <Stack horizontal grow id={answer.message_id}>
                        <Stack.Item grow>
                            <ReactMarkdown
                                linkTarget="_blank"
                                remarkPlugins={[remarkGfm, supersub]}
                                children={SANITIZE_ANSWER ? DOMPurify.sanitize(parsedAnswer.markdownFormatText, {ALLOWED_TAGS: XSSAllowTags}) : parsedAnswer.markdownFormatText}
                                className={styles.answerText}
                            />
                        </Stack.Item>
                    </Stack>
                    
                </Stack.Item>
                <Stack horizontal className={styles.answerFooter}>
                {!!parsedAnswer.citations.length && (
                    <Stack.Item
                        onKeyDown={e => e.key === "Enter" || e.key === " " ? toggleIsRefAccordionOpen() : null}
                    >
                        <Stack style={{width: "100%"}} >
                            <Stack horizontal horizontalAlign='start' verticalAlign='center'>
                                <Text
                                    className={styles.accordionTitle}
                                    onClick={toggleIsRefAccordionOpen}
                                    aria-label="Open references"
                                    tabIndex={0}
                                    role="button"
                                >
                                <span>{parsedAnswer.citations.length > 1 ? parsedAnswer.citations.length + " references" : "1 reference"}</span>
                                </Text>
                                <FontIcon className={styles.accordionIcon}
                                onClick={handleChevronClick} iconName={chevronIsExpanded ? 'ChevronDown' : 'ChevronRight'}
                                />
                            </Stack>
                            
                        </Stack>
                    </Stack.Item>
                )}
                { answer.module_name === undefined && appStateContext?.state.isGenerating != true && (
                    <Stack.Item className={styles.answerDisclaimerContainer}>
                        <span className={styles.answerDisclaimer}>AI-generated content may be incorrect</span>
                    </Stack.Item>)} 
                { answer.module_name != undefined && (<Stack.Item className={styles.answerDisclaimerContainer}>
                        <span className={styles.answerDisclaimer}>Chosen Intent: {answer.module_name}</span>
                    </Stack.Item>)}
                {appStateContext?.state.frontendSettings?.dev_mode !== false && (
                <Stack.Item>
                    <button onClick={generatePDF}>
                        <FontAwesomeIcon icon={faDownload} /> PDF
                    </button>
                </Stack.Item>
                )}
                <Stack.Item>
                    <button onClick={downloadWord}>
                        <FontAwesomeIcon icon={faDownload} /> Word
                    </button>
                </Stack.Item>
                {showEMLButton && (
                <Stack.Item>
                    <button onClick={generateEmail}>
                        <FontAwesomeIcon icon={faDownload} /> Email
                    </button>
                </Stack.Item>
                )}
                <Stack.Item className={styles.answerHeader}>
                    {FEEDBACK_ENABLED && answer.message_id !== undefined && <Stack horizontal horizontalAlign="space-between">
                        <ThumbLike20Filled
                            aria-hidden="false"
                            aria-label="Like this response"
                            onClick={() => onLikeResponseClicked()}
                            style={feedbackState === Feedback.Positive || appStateContext?.state.feedbackState[answer.message_id] === Feedback.Positive ? 
                                { color: "darkgreen", cursor: "pointer" } : 
                                { color: "slategray", cursor: "pointer" }}
                        />
                        <ThumbDislike20Filled
                            aria-hidden="false"
                            aria-label="Dislike this response"
                            onClick={() => onDislikeResponseClicked()}
                            style={(feedbackState !== Feedback.Positive && feedbackState !== Feedback.Neutral && feedbackState !== undefined) ? 
                                { color: "darkred", cursor: "pointer" } : 
                                { color: "slategray", cursor: "pointer" }}
                        />
                    </Stack>}
                </Stack.Item>
                </Stack>
                {chevronIsExpanded && 
                    <div style={{ marginTop: 8, display: "flex", flexFlow: "wrap column", maxHeight: "150px", gap: "4px" }}>
                        {parsedAnswer.citations.map((citation, idx) => {
                            return (
                                <span 
                                    title={createCitationFilepath(citation, ++idx)} 
                                    tabIndex={0} 
                                    role="link" 
                                    key={idx} 
                                    onClick={() => onCitationClicked(citation)} 
                                    onKeyDown={e => e.key === "Enter" || e.key === " " ? onCitationClicked(citation) : null}
                                    className={styles.citationContainer}
                                    aria-label={createCitationFilepath(citation, idx)}
                                >
                                    <div className={styles.citation}>{idx}</div>
                                    {citation.url != undefined ? (
                                      <a href={citation.url} target="_blank" rel="noopener noreferrer">
                                        {createCitationFilepath(citation, idx, true)}
                                      </a>
                                    ) : (
                                      createCitationFilepath(citation, idx, true)
                                    )}
                                </span>);
                        })}
                    </div>
                }
            </Stack>
            <Dialog 
                onDismiss={() => {
                    resetFeedbackDialog();
                    setFeedbackState(Feedback.Neutral);
                }}
                hidden={!isFeedbackDialogOpen}
                styles={{
                    
                    main: [{
                        selectors: {
                          ['@media (min-width: 480px)']: {
                            maxWidth: '600px',
                            background: "#FFFFFF",
                            boxShadow: "0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)",
                            borderRadius: "8px",
                            maxHeight: '600px',
                            minHeight: '100px',
                          }
                        }
                      }]
                }}
                dialogContentProps={{
                    title: "Submit Feedback",
                    showCloseButton: true
                }}
            >
                <Stack tokens={{childrenGap: 4}}>
                    <div>Your feedback will improve this experience.</div>
                    
                    {!showReportInappropriateFeedback ? <UnhelpfulFeedbackContent/> : <ReportInappropriateFeedbackContent/>}
                    
                    <div>By pressing submit, your feedback will be visible to the application owner.</div>
                    
                    <DefaultButton disabled={negativeFeedbackList.length < 1} onClick={onSubmitNegativeFeedback}>Submit</DefaultButton>
                </Stack>
                
            </Dialog>
        </>
    );
};
