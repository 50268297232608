import { useRef, useState, useEffect, useContext, useLayoutEffect } from "react";
import { CommandBarButton, IconButton, Dialog, DialogType, Stack,TextField, PrimaryButton } from "@fluentui/react";
import { SquareRegular, ShieldLockRegular, ErrorCircleRegular,CopyRegular } from "@fluentui/react-icons";

import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import rehypeRaw from "rehype-raw";
import uuid from 'react-uuid';
import { isEmpty, result, set } from "lodash-es";
import DOMPurify from 'dompurify';

import styles from "./Chat.module.css";
import TwoWTech from "../../assets/Icon With Bot-light.svg";
import TwoWTechDark from "../../assets/Icon With Bot-dark.svg";
import RIQBot from "../../assets/Resolve IQ Bot with bulb.svg";
import { XSSAllowTags } from "../../constants/xssAllowTags";
import { ShareButton } from "../../components/common/Button";


import {
    ChatMessage,
    ConversationRequest,
    conversationApi,
    Citation,
    CitationsMessageContent,
    ChatResponse,
    getUserInfo,
    Conversation,
    historyGenerate,
    historyUpdate,
    historyClear,
    ChatHistoryLoadingState,
    ModuleLoadingState,
    CosmosDBStatus,
    ErrorMessage,
    parseFile,
    historyDelete
} from "../../api";
import { Answer } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { AppStateContext } from "../../state/AppProvider";
import { useBoolean } from "@fluentui/react-hooks";
import { Question } from "../../components/Question/Question";
import { MenuPanel } from "../../components/Menu/MenuPanel";
const enum messageStatus {
    NotRunning = "Not Running",
    Processing = "Processing",
    Done = "Done"
}

const Chat = () => {
    const appStateContext = useContext(AppStateContext)
    const ui = appStateContext?.state.frontendSettings?.ui;
    const AUTH_ENABLED = appStateContext?.state.frontendSettings?.auth_enabled;
    const [isSharePanelOpen, setIsSharePanelOpen] = useState<boolean>(false);
    const [copyURLClicked, setCopyURLClicked] = useState<boolean>(false);
    const [copyURLText, setCopyURL] = useState<string>("Copy URL");
    const [copyMessageClicked, setCopyMessageClicked] = useState<boolean>(false);
    const [copyMessageText, setCopyMessage] = useState<string>("");
    const [shareLabel, setShareLabel] = useState<string | undefined>("Share");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);
    const [activeCitation, setActiveCitation] = useState<Citation>();
    const [isCitationPanelOpen, setIsCitationPanelOpen] = useState<boolean>(false);
    const abortFuncs = useRef([] as AbortController[]);
    const [showAuthMessage, setShowAuthMessage] = useState<boolean>(true);
    const [messages, setMessages] = useState<ChatMessage[]>([])
    const [processMessages, setProcessMessages] = useState<messageStatus>(messageStatus.NotRunning);
    const [clearingChat, setClearingChat] = useState<boolean>(false);
    const [hideErrorDialog, { toggle: toggleErrorDialog }] = useBoolean(true);
    const [errorMsg, setErrorMsg] = useState<ErrorMessage | null>()
    const [dots, setDots] = useState('');

    const errorDialogContentProps = {
        type: DialogType.close,
        title: errorMsg?.title,
        closeButtonAriaLabel: 'Close',
        subText: errorMsg?.subtitle,
    };

    const removeMessage = () => {

        const newMessages = appStateContext?.state.currentChat;
        if (newMessages) {
            // If a message was removed from the chat, it needs to be removed from "messages" as well
            if (newMessages.messages && newMessages.messages.length < messages.length) {
                // Find the message that was removed
                let removedMessage = messages.filter((message) => !newMessages.messages.some((newMessage) => newMessage.id === message.id));
                const element = document.getElementById(removedMessage.length > 0 ? removedMessage[0].id : '');
                // Filter out the removed message from "messages"
                let remainingMessages = messages.filter((message) => message.id !== removedMessage[0].id);
                setMessages(remainingMessages);
                if (element) {
                    // Remove the message from the Page
                    element.remove();
                }
            }
        }
    };

    useEffect(() => {
        removeMessage();
    }, [appStateContext?.state.currentChat]);

    const modalProps = {
        titleAriaId: 'labelId',
        subtitleAriaId: 'subTextId',
        isBlocking: true,
        styles: { main: { maxWidth: 450 } },
    }

    const [ASSISTANT, TOOL, CITATIONS, ERROR] = ["assistant", "tool", "citations", "error"]

    const handleShareClick = () => {
        setIsSharePanelOpen(true);
    };

    const handleSharePanelDismiss = () => {
        setIsSharePanelOpen(false);
        setCopyURLClicked(false);
        setCopyURL("Copy URL");
    };

    const handleModuleDefPanelDismiss = () => {
        appStateContext?.dispatch({ type: 'TOGGLE_MODULE_DEF', payload: false });
        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_MODULE', payload: null });
    }

    const handleCopyURLClick = () => {
        navigator.clipboard.writeText(window.location.href + "?conversationId=" + appStateContext?.state.currentChat?.id);
        setCopyURLClicked(true);
    };

    
    const handleCopyMessageClick = (question: ChatMessage | null) => {
        if (!question) return;

        const input = document.getElementById(question.content);

        if (input) {
            const text = input.textContent;
            navigator.clipboard.writeText(text || '')
                .then(() => {
                    setCopyMessage("Copied!");
                    setCopyMessageClicked(true);
                    setTimeout(() => {
                        setCopyMessage("");
                        setCopyMessageClicked(false);
                    }, 2000);
                })
                .catch((err) => {
                    console.error('Failed to copy text to clipboard', err);
                });
        }
    };

    useEffect(() => {
        if (copyURLClicked) {
            setCopyURL("Copied URL");
        }
    }, [copyURLClicked]);

    useEffect(() => {
        if (copyMessageClicked) {
            setCopyMessage("Copied!");
        }
    }, [copyMessageClicked]);

    const handleErrorDialogClose = () => {
        toggleErrorDialog()
        setTimeout(() => {
            setErrorMsg(null)
        }, 500);
    }

    useEffect(() => {
        let interval: number;

        // Function to add a dot every second
        const addDot = () => { 
            setDots(prevDots => prevDots.length == 6 ? '' : prevDots + '.');
        };

        // Start adding dots if showLoadingMessage is true
        if (showLoadingMessage) {
            interval = window.setInterval(addDot, 1000);
        } else {
            setDots(''); // Reset dots to none when showLoadingMessage is false
        }

        return () => clearInterval(interval); // Cleanup on unmount or when showLoadingMessage changes
    }, [showLoadingMessage]);


    useEffect(() => {
        if (appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.Working  
            && appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured
            && appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Fail 
            && hideErrorDialog) {
            let subtitle = `${appStateContext.state.isCosmosDBAvailable.status}. Please contact the site administrator.`
            setErrorMsg({
                title: "Chat history is not enabled",
                subtitle: subtitle
            })
            toggleErrorDialog();
        }
    }, [appStateContext?.state.isCosmosDBAvailable]);

    useEffect(() => {
        appStateContext?.dispatch({ type: 'SET_GENERATING_STATE', payload: (appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Loading) });
    }, [appStateContext?.state.chatHistoryLoadingState])

    useEffect(() => {
        if (appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.Working  
            && appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured
            && appStateContext?.state.moduleLoadingState === ModuleLoadingState.Fail 
            && hideErrorDialog) {
            let subtitle = `${appStateContext.state.isCosmosDBAvailable.status}. Please contact the site administrator.`
            setErrorMsg({
                title: "Module List is not enabled",
                subtitle: subtitle
            })
            toggleErrorDialog();
        }
    }, [appStateContext?.state.isCosmosDBAvailable]);

    useEffect(() => {
        appStateContext?.dispatch({ type: 'SET_GENERATING_STATE', payload: (appStateContext?.state.moduleLoadingState === ModuleLoadingState.Loading) });
    }, [appStateContext?.state.moduleLoadingState])


    const getUserInfoList = async () => {
        if (!AUTH_ENABLED) {
            setShowAuthMessage(false);
            return;
        }
        const userInfoList = await getUserInfo();
        console.log(userInfoList);
        if (userInfoList.length === 0 && window.location.hostname !== "127.0.0.1") {
            setShowAuthMessage(true);
        }
        else {
            setShowAuthMessage(false);
        }
    }

    let assistantMessage = {} as ChatMessage
    let toolMessage = {} as ChatMessage
    let citationsMessage = {} as ChatMessage
    let assistantContent = ""    

    const processResultMessage = (resultMessage: ChatMessage, userMessage: ChatMessage, conversationId?: string, module_name?: string) => {
        if (resultMessage.role === ASSISTANT) {
            assistantContent += resultMessage.content
            assistantMessage = resultMessage
            assistantMessage.content = assistantContent
            if (module_name !== undefined){
                assistantMessage.module_name = module_name
            }

            if (resultMessage.context) {
                citationsMessage = {
                    id: resultMessage.id + "-citations",
                    role: CITATIONS,
                    content: resultMessage.context,
                    date: new Date().toISOString(),
                }
            }
        }

        if (resultMessage.role === CITATIONS) {
            citationsMessage = {
                id: resultMessage.id + "-citations",
                role: CITATIONS,
                content: resultMessage.content,
                date: new Date().toISOString(),
            }
        }

        if (resultMessage.role === TOOL) toolMessage = resultMessage

        if (!conversationId) {
            isEmpty(toolMessage) ?
                isEmpty(citationsMessage) ?
                    setMessages([...messages, userMessage, assistantMessage]) :
                    setMessages([...messages, userMessage, citationsMessage, assistantMessage]):
                isEmpty(citationsMessage) ?
                    setMessages([...messages, userMessage, toolMessage, assistantMessage]):
                    setMessages([...messages, userMessage, toolMessage, citationsMessage, assistantMessage]);
        } else {
            isEmpty(toolMessage) ?
                isEmpty(citationsMessage) ?
                    setMessages([...messages, assistantMessage]) :
                    setMessages([...messages, citationsMessage, assistantMessage]):
                isEmpty(citationsMessage) ?
                    setMessages([...messages, toolMessage, assistantMessage]):
                    setMessages([...messages, toolMessage, citationsMessage, assistantMessage]);
        }
    }

    const makeApiRequestWithoutCosmosDB = async (question: string, files?: File[], conversationId?: string) => {
        appStateContext?.dispatch({ type: 'SET_GENERATING_STATE', payload:true });
        setShowLoadingMessage(true);
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);

        let file_content = []
        if(files != null && files != undefined){
            appStateContext?.dispatch({ type: 'TOGGLE_FILE_ANALYSIS', payload: true });
        }
        if (files) {
            for (let i = 0; i < files?.length; i++) {
                let parsed_file = await parseFile(files[i]);
                for (let j = 0; j < parsed_file.length; j++) {
                    file_content.push(parsed_file[j]);
                }
            }
        }
        appStateContext?.dispatch({ type: 'TOGGLE_FILE_ANALYSIS', payload: false });

        const userMessage: ChatMessage = {
            id: uuid(),
            role: "user",
            content: question,
            date: new Date().toISOString(),
        };

        appStateContext?.dispatch({ type: 'SET_LOADED_QUESTION', payload: null });

        if (files){
            userMessage.files = file_content;
        }

        let conversation: Conversation | null | undefined;
        if (!conversationId) {
            conversation = {
                id: conversationId ?? uuid(),
                title: question,
                messages: [userMessage],
                date: new Date().toISOString(),
            }
        } else {
            conversation = appStateContext?.state?.currentChat
            if (!conversation) {
                console.error("Conversation not found.");
                appStateContext?.dispatch({ type: 'SET_GENERATING_STATE', payload: false });
                setShowLoadingMessage(false);
                abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                return;
            } else {
                conversation.messages.push(userMessage);
            }
        }

        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
        setMessages(conversation.messages)

        const request: ConversationRequest = {
            messages: [...conversation.messages.filter((answer) => answer.role !== ERROR)]
        };
        request.focus = appStateContext?.state.focusIntent ? appStateContext?.state.focusIntent : [];
        request.client_state = appStateContext?.state.clientState ? appStateContext?.state.clientState : null;
        let result = {} as ChatResponse;
        try {
            const response = await conversationApi(request, abortController.signal);
            if (response?.body) {
                const reader = response.body.getReader();

                let runningText = "";
                while (true) {
                    setProcessMessages(messageStatus.Processing)
                    const { done, value } = await reader.read();
                    if (done) break;

                    var text = new TextDecoder("utf-8").decode(value);
                    const objects = text.split("\n");
                    objects.forEach((obj) => {
                        try {
                            if (obj !== "" && obj !== "{}") {
                                runningText += obj;
                                result = JSON.parse(runningText);
                                if (result.choices?.length > 0) {
                                    result.choices[0].messages.forEach((msg) => {
                                        msg.id = result.id;
                                        msg.date = new Date().toISOString();
                                    })
                                    if (result.choices[0].messages?.some(m => m.role === ASSISTANT)) {
                                        setShowLoadingMessage(false);
                                    }
                                    let module_name = result.chosen_intent?.name !== undefined ? result.chosen_intent.name : undefined;
                                    result.choices[0].messages.forEach((resultObj) => {
                                        processResultMessage(resultObj, userMessage, conversationId, module_name);
                                    })
                                }
                                else if (result.error) {
                                    throw Error(result.error);
                                }
                                runningText = "";
                            }
                        }
                        catch (e) {
                            if (!(e instanceof SyntaxError)) {
                                console.error(e);
                                throw e;
                            } else {
                                console.log("Incomplete message. Continuing...")
                            }
                        }
                    });
                }
                conversation.messages.push(toolMessage, citationsMessage, assistantMessage)
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
                setMessages([...messages, toolMessage, citationsMessage, assistantMessage]);
            }

        } catch (e) {
            if (!abortController.signal.aborted) {
                let errorMessage = "An error occurred. Please try again. If the problem persists, please contact the site administrator.";
                if (result.error?.message) {
                    errorMessage = result.error.message;
                }
                else if (typeof result.error === "string") {
                    errorMessage = result.error;
                }
                let errorChatMsg: ChatMessage = {
                    id: uuid(),
                    role: ERROR,
                    content: errorMessage,
                    date: new Date().toISOString()
                }
                conversation.messages.push(errorChatMsg);
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
                setMessages([...messages, errorChatMsg]);
            } else {
                setMessages([...messages, userMessage])
            }
        } finally {
            appStateContext?.dispatch({ type: 'SET_GENERATING_STATE', payload:false });
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
            setProcessMessages(messageStatus.Done)
        }

        return abortController.abort();
    };

    const makeApiRequestWithCosmosDB = async (question: string, files?: File[],  conversationId?: string) => {
        appStateContext?.dispatch({ type: 'SET_GENERATING_STATE', payload:true });
        setShowLoadingMessage(true);
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);

        let file_content = []
        if(files != null && files != undefined){
            appStateContext?.dispatch({ type: 'TOGGLE_FILE_ANALYSIS', payload: true });
        }
        if (files) {
            for (let i = 0; i < files?.length; i++) {
                let parsed_file = await parseFile(files[i]);
                for (let j = 0; j < parsed_file.length; j++) {
                    file_content.push(parsed_file[j]);
                }
            }
        }
        appStateContext?.dispatch({ type: 'TOGGLE_FILE_ANALYSIS', payload: false });

        const userMessage: ChatMessage = {
            id: uuid(),
            role: "user",
            content: question,
            date: new Date().toISOString(),
        };

        appStateContext?.dispatch({ type: 'SET_LOADED_QUESTION', payload: null });

        if (files){
            userMessage.files = file_content;
        }

        //api call params set here (generate)
        let request: ConversationRequest;
        let conversation;
        if (conversationId) {
            conversation = appStateContext?.state?.chatHistory?.find((conv) => conv.id === conversationId)
            if (!conversation) {
                console.error("Conversation not found.");
                appStateContext?.dispatch({ type: 'SET_GENERATING_STATE', payload:false });
                setShowLoadingMessage(false);
                abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                return;
            } else {
                conversation.messages.push(userMessage);
                request = {
                    messages: [...conversation.messages.filter((answer) => answer.role !== ERROR)]
                };
            }
        } else {
            request = {
                messages: [userMessage].filter((answer) => answer.role !== ERROR)
            };
            setMessages(request.messages)
        }
        request.focus = appStateContext?.state.focusIntent ? appStateContext?.state.focusIntent : [];
        request.client_state = appStateContext?.state.clientState ? appStateContext?.state.clientState : null;
        let result = {} as ChatResponse;
        try {
            const response = conversationId ? await historyGenerate(request, abortController.signal, conversationId) : await historyGenerate(request, abortController.signal);
            if (!response?.ok) {
                const responseJson = await response.json();
                var errorResponseMessage = responseJson.error === undefined ? "Please try again. If the problem persists, please contact the site administrator." : responseJson.error;
                let errorChatMsg: ChatMessage = {
                    id: uuid(),
                    role: ERROR,
                    content: "There was an error generating your response. Please try again later. If this issue persists, please contact support and provide the following id:"+{conversationId},
                    date: new Date().toISOString()
                }
                let resultConversation;
                if (conversationId) {
                    resultConversation = appStateContext?.state?.chatHistory?.find((conv) => conv.id === conversationId)
                    if (!resultConversation) {
                        console.error("Conversation not found.");
                        appStateContext?.dispatch({ type: 'SET_GENERATING_STATE', payload: false });
                        setShowLoadingMessage(false);
                        abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                        return;
                    }
                    resultConversation.messages.push(errorChatMsg);
                } else {
                    setMessages([...messages, userMessage, errorChatMsg])
                    appStateContext?.dispatch({ type: 'SET_GENERATING_STATE', payload: false });
                    setShowLoadingMessage(false);
                    abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                    return;
                }
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: resultConversation });
                setMessages([...resultConversation.messages]);
                return;
            }
            if (response?.body) {
                const reader = response.body.getReader();

                let runningText = "";
                while (true) {
                    setProcessMessages(messageStatus.Processing)
                    const { done, value } = await reader.read();
                    if (done) break;

                    var text = new TextDecoder("utf-8").decode(value);
                    const objects = text.split("\n");
                    objects.forEach((obj) => {
                        try {
                            if (obj !== "" && obj !== "{}") {
                                runningText += obj;
                                result = JSON.parse(runningText);
                                if (result.choices?.length > 0) {
                                    result.choices[0].messages.forEach((msg) => {
                                        msg.id = result.id;
                                        msg.date = new Date().toISOString();
                                    })
                                    if (result.choices[0].messages?.some(m => m.role === ASSISTANT)) {
                                        setShowLoadingMessage(false);
                                    }
                                    let module_name = result.chosen_intent?.name !== undefined ? result.chosen_intent.name : undefined;
                                    result.choices[0].messages.forEach((resultObj) => {
                                        processResultMessage(resultObj, userMessage, conversationId, module_name);
                                    })
                                }
                                runningText = "";
                            }
                            else if (result.error) {
                                throw Error(result.error);
                            }
                        }
                        catch (e) {
                            if (!(e instanceof SyntaxError)) {
                                console.error(e);
                                throw e;
                            } else {
                                console.log("Incomplete message. Continuing...")
                            }
                         }
                    });
                }

                let resultConversation;
                if (conversationId) {
                    resultConversation = appStateContext?.state?.chatHistory?.find((conv) => conv.id === conversationId)
                    if (!resultConversation) {
                        console.error("Conversation not found.");
                        appStateContext?.dispatch({ type: 'SET_GENERATING_STATE', payload: false });
                        setShowLoadingMessage(false);
                        abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                        return;
                    }
                    isEmpty(toolMessage) ?
                        isEmpty(citationsMessage) ?
                            resultConversation.messages.push(assistantMessage) :
                            resultConversation.messages.push(citationsMessage, assistantMessage) :
                        isEmpty(citationsMessage) ?
                            resultConversation.messages.push(toolMessage, assistantMessage) :
                            resultConversation.messages.push(toolMessage, citationsMessage, assistantMessage);
                } else {
                    resultConversation = {
                        id: result.history_metadata.conversation_id,
                        title: result.history_metadata.title,
                        messages: [userMessage],
                        date: result.history_metadata.date
                    }
                    isEmpty(toolMessage) ?
                        isEmpty(citationsMessage) ?
                            resultConversation.messages.push(assistantMessage) :
                            resultConversation.messages.push(citationsMessage, assistantMessage) :
                        isEmpty(citationsMessage) ?
                            resultConversation.messages.push(toolMessage, assistantMessage) :
                            resultConversation.messages.push(toolMessage, citationsMessage, assistantMessage);
                }
                if (!resultConversation) {
                    appStateContext?.dispatch({ type: 'SET_GENERATING_STATE', payload: false });
                    setShowLoadingMessage(false);
                    abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                    return;
                }
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: resultConversation });
                isEmpty(toolMessage) ?
                    isEmpty(citationsMessage) ?
                        setMessages([...messages, assistantMessage]) :
                        setMessages([...messages, citationsMessage, assistantMessage]) :
                    isEmpty(citationsMessage) ?
                        setMessages([...messages, toolMessage, assistantMessage]) :
                        setMessages([...messages, toolMessage, citationsMessage, assistantMessage]);
            }

        } catch (e) {
            if (!abortController.signal.aborted) {
                let errorMessage = `An error occurred. ${errorResponseMessage}`;
                if (result.error?.message) {
                    errorMessage = result.error.message;
                }
                else if (typeof result.error === "string") {
                    errorMessage = result.error;
                }
                let errorChatMsg: ChatMessage = {
                    id: uuid(),
                    role: ERROR,
                    content: errorMessage,
                    date: new Date().toISOString()
                }
                let resultConversation;
                if (conversationId) {
                    resultConversation = appStateContext?.state?.chatHistory?.find((conv) => conv.id === conversationId)
                    if (!resultConversation) {
                        console.error("Conversation not found.");
                        appStateContext?.dispatch({ type: 'SET_GENERATING_STATE', payload: false });
                        setShowLoadingMessage(false);
                        abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                        return;
                    }
                    resultConversation.messages.push(errorChatMsg);
                } else {
                    if (!result.history_metadata) {
                        console.error("Error retrieving data.", result);
                        console.log("errorMessage", errorMessage)
                        let errorChatMsg: ChatMessage = {
                            id: uuid(),
                            role: ERROR,
                            content: errorMessage,
                            date: new Date().toISOString()
                        } 
                        setMessages([...messages, userMessage, errorChatMsg])
                        appStateContext?.dispatch({ type: 'SET_GENERATING_STATE', payload: false });
                        setShowLoadingMessage(false);
                        abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                        return;
                    }
                    resultConversation = {
                        id: result.history_metadata.conversation_id,
                        title: result.history_metadata.title,
                        messages: [userMessage],
                        date: result.history_metadata.date
                    }
                    resultConversation.messages.push(errorChatMsg);
                }
                if (!resultConversation) {
                    appStateContext?.dispatch({ type: 'SET_GENERATING_STATE', payload: false });
                    setShowLoadingMessage(false);
                    abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                    return;
                }
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: resultConversation });
                setMessages([...messages, errorChatMsg]);
            } else {
                setMessages([...messages, userMessage])
            }
        } finally {
            appStateContext?.dispatch({ type: 'SET_GENERATING_STATE', payload: false });
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
            setProcessMessages(messageStatus.Done)
        }
        return abortController.abort();

    }

    const clearChat = async () => {
        setClearingChat(true)
        if (appStateContext?.state.currentChat?.id && appStateContext?.state.isCosmosDBAvailable.cosmosDB) {
            let response = await historyDelete(appStateContext?.state.currentChat.id)
            if (!response.ok) {
                setErrorMsg({
                    title: "Error clearing current chat",
                    subtitle: "Please try again. If the problem persists, please contact the site administrator.",
                })
                toggleErrorDialog();
            } else {
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: null });
                setActiveCitation(undefined);
                setIsCitationPanelOpen(false);
                setMessages([])
            }
        }
        appStateContext?.dispatch({ type: 'TOGGLE_SHARED_CONV', payload: false })
        window.history.pushState({}, document.title, "/");
        setClearingChat(false)
    };

    const newChat = () => {
        setProcessMessages(messageStatus.Processing)
        setMessages([])
        setIsCitationPanelOpen(false);
        setActiveCitation(undefined);
        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: null });
        setProcessMessages(messageStatus.Done)
        appStateContext?.dispatch({ type: 'TOGGLE_SHARED_CONV', payload: false })
        window.history.pushState({}, document.title, "/");
    };

    const stopGenerating = () => {
        abortFuncs.current.forEach(a => a.abort());
        setShowLoadingMessage(false);
        appStateContext?.dispatch({ type: 'SET_GENERATING_STATE', payload: false });
    }

    const passQuickQuery = (query: string) => {
        appStateContext?.dispatch({ type: 'SET_LOADED_QUESTION', payload: query });
    }

    useEffect(() => {
        if (appStateContext?.state.currentChat) {
            setMessages(appStateContext.state.currentChat.messages)
        } else {
            setMessages([])
        }
    }, [appStateContext?.state.currentChat]);

    useLayoutEffect(() => {
        const saveToDB = async (messages: ChatMessage[], id: string) => {
            const response = await historyUpdate(messages, id)
            return response
        }

        if (appStateContext && appStateContext.state.currentChat && processMessages === messageStatus.Done) {
            if (appStateContext.state.isCosmosDBAvailable.cosmosDB) {
                if (!appStateContext?.state.currentChat?.messages) {
                    console.error("Failure fetching current chat state.")
                    return
                }
                saveToDB(appStateContext.state.currentChat.messages, appStateContext.state.currentChat.id)
                    .then((res) => {
                        if (!res.ok) {
                            let errorMessage = "An error occurred. Answers can't be saved at this time. If the problem persists, please contact the site administrator.";
                            let errorChatMsg: ChatMessage = {
                                id: uuid(),
                                role: ERROR,
                                content: errorMessage,
                                date: new Date().toISOString()
                            }
                            if (!appStateContext?.state.currentChat?.messages) {
                                let err: Error = {
                                    ...new Error,
                                    message: "Failure fetching current chat state."
                                }
                                throw err
                            }
                            setMessages([...appStateContext?.state.currentChat?.messages, errorChatMsg])
                        }
                        return res as Response
                    })
                    .catch((err) => {
                        console.error("Error: ", err)
                        let errRes: Response = {
                            ...new Response,
                            ok: false,
                            status: 500,
                        }
                        return errRes;
                    })
            } else {
            }
            appStateContext?.dispatch({ type: 'UPDATE_CHAT_HISTORY', payload: appStateContext.state.currentChat });
            setMessages(appStateContext.state.currentChat.messages)
            setProcessMessages(messageStatus.NotRunning)
        }
    }, [processMessages]);

    useEffect(() => {
        if (AUTH_ENABLED !== undefined) getUserInfoList();
    }, [AUTH_ENABLED]);

    useLayoutEffect(() => {
        chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" })
    }, [showLoadingMessage, processMessages]);

    const onShowCitation = (citation: Citation) => {
        setActiveCitation(citation);
        setIsCitationPanelOpen(true);
    };

    const onViewSource = (citation: Citation) => {
        if (citation.url && !citation.url.includes("blob.core")) {
            window.open(citation.url, "_blank");
        }
    };

    const parseCitationFromMessage = (message: ChatMessage | undefined) => {
        if (message !== undefined && message?.role && message?.role === CITATIONS) {
            try {
                const toolMessage = JSON.parse(message.content) as CitationsMessageContent;
                return toolMessage.citations;
            }
            catch {
                return [];
            }
        }
        return [];
    }

    const disabledButton = () => {
        return appStateContext?.state.isGenerating || (messages && messages.length === 0) || clearingChat || appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Loading
    }

    return (
        <div className={appStateContext?.state.isDarkMode ? styles.darkmodeContainer : styles.container} role="main">
            {showAuthMessage ? (
                <Stack className={styles.chatEmptyState}>
                    <ShieldLockRegular className={styles.chatIcon} style={{ color: 'darkblue', height: "200px", width: "200px" }} />
                    <h1 className={appStateContext?.state.isDarkMode ? styles.darkmodeChatEmptyStateTitle : styles.chatEmptyStateTitle}>Welcome to ResolveIQ!</h1>
                    <h2 className={appStateContext?.state.isDarkMode ? styles.darkmodeChatEmptyStateSubtitle : styles.chatEmptyStateSubtitle}>
                        Unfortunately, we couldn't authenticate you. If you believe this is in error, please contact the 2W Technologies Helpdesk at <a href="mailto:helpdesk@2wtech.com">Helpdesk@2wtech.com</a> or call us at (262)-686-5070.
                    </h2>
                </Stack>
            ) : (
                <Stack horizontal className={styles.chatRoot}>
                    <div className={appStateContext?.state.isDarkMode ? (appStateContext?.state.deviceType?.isDesktop == true ? styles.darkmodeChatContainer : styles.darkmodeChatContainerMobile) : (appStateContext?.state.deviceType?.isDesktop == true ? styles.chatContainer : styles.chatContainerMobile)}>
                    {appStateContext?.state.deviceType?.isDesktop !== true ? 
                        (<Stack horizontal tokens={{ childrenGap: 10 }} style={{width: "100%"}}>
                            {appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured && <CommandBarButton
                                role="button"
                                styles={{
                                    icon: {
                                        color: '#FFFFFF',
                                    },
                                    iconDisabled: {
                                        color: "#BDBDBD !important"
                                    },
                                    root: {
                                        color: '#FFFFFF',
                                        background: "radial-gradient(109.81% 107.82% at 100.1% 90.19%, #0F6CBD 33.63%, #2D87C3 70.31%, #8DDDD8 100%)"
                                    },
                                    rootDisabled: {
                                        background: "#F0F0F0"
                                    }
                                }}
                                className={styles.newChatIconMobile}
                                iconProps={{ iconName: 'Add' }}
                                onClick={newChat}
                                disabled={disabledButton()}
                                aria-label="start a new chat button"
                            />}
                            <CommandBarButton
                                role="button"
                                styles={{
                                    icon: {
                                        color: '#FFFFFF',
                                    },
                                    iconDisabled: {
                                        color: "#BDBDBD !important",
                                    },
                                    root: {
                                        color: '#FFFFFF',
                                        background: "radial-gradient(109.81% 107.82% at 100.1% 90.19%, #0F6CBD 33.63%, #2D87C3 70.31%, #8DDDD8 100%)",
                                    },
                                    rootDisabled: {
                                        background: "#F0F0F0"
                                    }
                                }}
                                className={appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured ? styles.clearChatBroomMobile : styles.clearChatBroomNoCosmosMobile}
                                iconProps={{ iconName: 'Broom' }}
                                onClick={appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured ? clearChat : newChat}
                                disabled={disabledButton()}
                                aria-label="clear chat button"
                            />
                            <Dialog
                                hidden={hideErrorDialog}
                                onDismiss={handleErrorDialogClose}
                                dialogContentProps={errorDialogContentProps}
                                modalProps={modalProps}
                            >
                            </Dialog>
                        </Stack>) : null}
                        {!messages || messages.length >= 1 && appStateContext?.state.deviceType?.isDesktop == true ? (ui?.show_share_button &&<ShareButton onClick={handleShareClick} text={shareLabel} />) : null}
                        {!messages || messages.length < 1 ? (
                            <Stack className={styles.chatEmptyState}>
                                <img
                                    src={appStateContext?.state.isDarkMode ? ui?.dark_chat_logo ? ui.dark_chat_logo : ui?.chat_logo ? ui.chat_logo : TwoWTechDark : ui?.chat_logo ? ui.chat_logo : TwoWTech}
                                    className={styles.chatIcon}
                                    aria-hidden="true"
                                    id="chat_logo"
                                />
                                <h1 className={appStateContext?.state.isDarkMode ? styles.darkmodeChatEmptyStateTitle : styles.chatEmptyStateTitle}>{ui?.chat_title}</h1>
                                <h2 className={appStateContext?.state.isDarkMode ? styles.darkmodeChatEmptyStateSubtitle : styles.chatEmptyStateSubtitle}>{ui?.chat_description}</h2>
                            </Stack>
                        ) : (
                            <div className={styles.chatMessageStream} style={{ marginBottom: appStateContext?.state.isGenerating ? "40px" : "0px" }} role="log">
                                {messages.map((answer, index) => (
                                    <>
                                        {answer.role === "user" ? (
                                        //     <div className={styles.chatMessageGpt}>
                                        //     <Question
                                        //         question={answer}
                                        //     />
                                        // </div>
                                        <Stack tabIndex={0}>
                                        <Stack.Item className={styles.chatMessageUser}> 
                                            {/* <div
                                                className={styles.copyButtonContainer}
                                                role="button"
                                                tabIndex={0}
                                                aria-label="Copy"
                                                onClick={handleCopyClick}
                                                onKeyDown={e => e.key === "Enter" || e.key === " " ? handleCopyClick() : null}
                                            >
                                                <CopyRegular className={styles.copyButton} />
                                                <span className={styles.copyButtonText}>{copyText}</span>
                                            </div> */}
                                            <div className={styles.chatMessageUserMessage}>
                                                {answer.content}
                                            </div>
                                        </Stack.Item>
                                        {answer.files && answer.files.map((file) => (
                                            <Stack.Item className={styles.chatMessageUserFileContainer} key={file.fileName}>
                                                <div className={appStateContext?.state.isDarkMode ? styles.darkChatMessageUserFile : styles.chatMessageUserFile}>
                                                    {file.fileName}
                                                    {file.fileEncrypted == false ? " - File data could not be encrypted so it will not be saved." : null}
                                                </div>
                                            </Stack.Item>
                                        ))}
                                    </Stack>
                                        ) : (
                                            answer.role === "assistant" ? <div className={styles.chatMessageGpt}>
                                                <Answer
                                                    answer={{
                                                        answer: answer.content,
                                                        citations: parseCitationFromMessage(messages.find(msg => msg.id === answer.id + "-citations")),
                                                        message_id: answer.id,
                                                        feedback: answer.feedback,
                                                        module_name: answer.module_name
                                                    }}
                                                    onCitationClicked={c => onShowCitation(c)}
                                                />
                                            </div>: answer.role === ERROR ? <div className={styles.chatMessageError}>
                                                <Stack horizontal className={styles.chatMessageErrorContent}>
                                                    <ErrorCircleRegular className={styles.errorIcon} style={{ color: "rgba(182, 52, 67, 1)", background: "transparent" }} />
                                                    <span>Error</span>
                                                </Stack>
                                                <span className={styles.chatMessageErrorContent}>{answer.content}</span>
                                            </div> : null
                                        )}
                                    </>
                                ))}
                                {showLoadingMessage && (
                                    <>
                                        <div className={styles.chatMessageGpt}>
                                            <Answer
                                                answer={{
                                                    answer: `Generating answer${dots}`,
                                                    citations: []
                                                }}
                                                onCitationClicked={() => null}
                                            />
                                        </div>
                                    </>
                                )}
                                <div ref={chatMessageStreamEnd} />
                            </div>
                        )}
                        {!appStateContext?.state.isGenerating && Array.isArray(appStateContext?.state.focusIntent) && appStateContext?.state.focusIntent?.some(intentItem => intentItem.quickQuery && intentItem.quickQuery.length > 0) && <div className={styles.quickQueriesParent}>
                                <Stack
                                    horizontal
                                    className={styles.quickQueriesContainer}
                                    role="button"
                                    aria-label={`Quick Queries`}
                                    tabIndex={0}
                                >
                                {appStateContext?.state.focusIntent.map((intentItem) => (
                                    intentItem.quickQuery?.map((query) => (
                                        
                                            <button 
                                                className={appStateContext?.state.isDarkMode ? styles.darkQuickQueriesText : styles.quickQueriesText} 
                                                aria-hidden="true"
                                                onClick={() => passQuickQuery(query)} 
                                            >
                                                {query}
                                            </button>
                                        
                                    ))))}
                                </Stack>
                            </div>
                            }
                        <Stack horizontal className={styles.chatInput}>
                            {appStateContext?.state.isGenerating && (
                                <Stack
                                    horizontal
                                    className={styles.stopGeneratingContainer}
                                    role="button"
                                    aria-label="Stop generating"
                                    tabIndex={0}
                                    onClick={stopGenerating}
                                    onKeyDown={e => e.key === "Enter" || e.key === " " ? stopGenerating() : null}
                                >
                                    <SquareRegular className={appStateContext?.state.isDarkMode ? styles.darkStopGeneratingIcon : styles.stopGeneratingIcon} aria-hidden="true" />
                                    <span className={appStateContext?.state.isDarkMode ? styles.darkStopGeneratingText : styles.stopGeneratingText} aria-hidden="true">Stop generating</span>
                                </Stack>
                            )}
                            {appStateContext?.state.deviceType?.isDesktop == true ? 
                            (<Stack className={styles.chatBotLogoContainer}>
                                <img
                                    src={RIQBot}
                                    className={styles.chatBotLogo}
                                    aria-hidden="true"
                                />
                            </Stack>) : null}
                            {appStateContext?.state.deviceType?.isDesktop == true ? 
                            (<Stack>
                                {appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured && <CommandBarButton
                                    role="button"
                                    styles={{
                                        icon: {
                                            color: '#FFFFFF',
                                        },
                                        iconDisabled: {
                                            color: "#BDBDBD !important"
                                        },
                                        root: {
                                            color: '#FFFFFF',
                                            background: "radial-gradient(109.81% 107.82% at 100.1% 90.19%, #0F6CBD 33.63%, #2D87C3 70.31%, #8DDDD8 100%)"
                                        },
                                        rootDisabled: {
                                            background: "#F0F0F0"
                                        }
                                    }}
                                    className={styles.newChatIcon}
                                    iconProps={{ iconName: 'Add' }}
                                    onClick={newChat}
                                    disabled={disabledButton()}
                                    aria-label="start a new chat button"
                                />}
                                <CommandBarButton
                                    role="button"
                                    styles={{
                                        icon: {
                                            color: '#FFFFFF',
                                        },
                                        iconDisabled: {
                                            color: "#BDBDBD !important",
                                        },
                                        root: {
                                            color: '#FFFFFF',
                                            background: "radial-gradient(109.81% 107.82% at 100.1% 90.19%, #0F6CBD 33.63%, #2D87C3 70.31%, #8DDDD8 100%)",
                                        },
                                        rootDisabled: {
                                            background: "#F0F0F0"
                                        }
                                    }}
                                    className={appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured ? styles.clearChatBroom : styles.clearChatBroomNoCosmos}
                                    iconProps={{ iconName: 'Broom' }}
                                    onClick={appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured ? clearChat : newChat}
                                    disabled={disabledButton()}
                                    aria-label="clear chat button"
                                />
                                <Dialog
                                    hidden={hideErrorDialog}
                                    onDismiss={handleErrorDialogClose}
                                    dialogContentProps={errorDialogContentProps}
                                    modalProps={modalProps}
                                >
                                </Dialog>
                            </Stack>) : null}
                            <QuestionInput
                                clearOnSend
                                placeholder="Type a new question..."
                                disabled={appStateContext?.state.isGenerating || false}
                                onSend={(question, file) => {
                                    appStateContext?.state.isCosmosDBAvailable?.cosmosDB ? makeApiRequestWithCosmosDB(question, file, appStateContext?.state?.currentChat?.id) : makeApiRequestWithoutCosmosDB(question, file, appStateContext?.state?.currentChat?.id)
                                }}
                                conversationId={appStateContext?.state.currentChat?.id ? appStateContext?.state.currentChat?.id : undefined}
                            />
                        </Stack>
                    </div>
                    {/* Citation Panel */}
                    {messages && messages.length > 0 && isCitationPanelOpen && activeCitation && (
                        <Stack.Item className={styles.citationPanel} tabIndex={0} role="tabpanel" aria-label="Citations Panel">
                            <Stack aria-label="Citations Panel Header Container" horizontal className={styles.citationPanelHeaderContainer} horizontalAlign="space-between" verticalAlign="center">
                                <span aria-label="Citations" className={styles.citationPanelHeader}>Citations</span>
                                <IconButton iconProps={{ iconName: 'Cancel' }} aria-label="Close citations panel" onClick={() => setIsCitationPanelOpen(false)} />
                            </Stack>
                            <h5 className={styles.citationPanelTitle} tabIndex={0} title={activeCitation.url && !activeCitation.url.includes("blob.core") ? activeCitation.url : activeCitation.title ?? ""} onClick={() => onViewSource(activeCitation)}>{activeCitation.title}</h5>
                            <div tabIndex={0}>
                                <ReactMarkdown
                                    linkTarget="_blank"
                                    className={styles.citationPanelContent}
                                    children={DOMPurify.sanitize(activeCitation.content, {ALLOWED_TAGS: XSSAllowTags})}
                                    remarkPlugins={[remarkGfm]}
                                    rehypePlugins={[rehypeRaw]}
                                />
                            </div>
                        </Stack.Item>
                    )}
                    {(appStateContext?.state.isMenuOpen) && <MenuPanel />}
                </Stack>
            )}
            <Dialog
                onDismiss={handleSharePanelDismiss}
                hidden={!isSharePanelOpen}
                styles={{

                    main: [{
                        selectors: {
                            ['@media (min-width: 480px)']: {
                                maxWidth: '600px',
                                background: "#FFFFFF",
                                boxShadow: "0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)",
                                borderRadius: "8px",
                                maxHeight: '200px',
                                minHeight: '100px',
                            }
                        }
                    }]
                }}
                dialogContentProps={{
                    title: "Share This Conversation!",
                    showCloseButton: true
                }}
            >
                <Stack horizontal verticalAlign="center" style={{ gap: "8px" }}>
                    <TextField className={styles.urlTextBox} defaultValue={window.location.href + "?conversationId=" + appStateContext?.state.currentChat?.id } readOnly />
                    <div
                        className={styles.copyButtonContainer}
                        role="button"
                        tabIndex={0}
                        aria-label="Copy"
                        onClick={handleCopyURLClick}
                        onKeyDown={e => e.key === "Enter" || e.key === " " ? handleCopyURLClick() : null}
                    >
                        <CopyRegular className={styles.copyButton} />
                        <span className={styles.copyButtonText}>{copyURLText}</span>
                    </div>
                </Stack>
            </Dialog>
            <Dialog
                onDismiss={handleModuleDefPanelDismiss}
                hidden={!appStateContext?.state.isModuleDefPanelOpen}
                styles={{

                    main: [{
                        selectors: {
                            ['@media (min-width: 480px)']: {
                                maxWidth: '1000px',
                                background: "#FFFFFF",
                                boxShadow: "0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)",
                                borderRadius: "8px",
                                maxHeight: '1000px',
                                minHeight: '100px',
                            }
                        }
                    }]
                }}
                dialogContentProps={{
                    title: appStateContext?.state.currentModule?.name,
                    showCloseButton: true
                }}
            >
                <Stack horizontal verticalAlign="center" horizontalAlign="center" style={{ gap: "8px", width: "100%" }}>
                    {(appStateContext?.state.deviceType?.isDesktop == false || !appStateContext?.state.currentModule?.video.includes("embed")) && <PrimaryButton onClick={() => window.open(appStateContext?.state.currentModule?.video, "_blank")}>View Module Video</PrimaryButton>}
                    {appStateContext?.state.deviceType?.isDesktop == true && appStateContext?.state.currentModule?.video.includes("embed") && <iframe width="560" height="315" src={appStateContext?.state.currentModule?.video} title="ResolveIQ Module Video" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>}
                </Stack>
                <Stack horizontal verticalAlign="center" style={{ gap: "8px", width: "100%" }}>
                    <h2>{appStateContext?.state.currentModule?.definition}</h2>
                </Stack>
            </Dialog>
            <Dialog
                hidden={!appStateContext?.state.isFileBeingAnalyzed}
                styles={{

                    main: [{
                        selectors: {
                            ['@media (min-width: 480px)']: {
                                maxWidth: '800px',
                                background: "#FFFFFF",
                                boxShadow: "0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)",
                                borderRadius: "8px",
                                maxHeight: '400px',
                                minHeight: '100px',
                            }
                        }
                    }]
                }}
                dialogContentProps={{
                    title: "Analyzing your file...",
                    showCloseButton: true
                }}
            >
            </Dialog>
        </div>        
    );
};

export default Chat;
