import { useState, useRef } from "react";
import { Stack, TextField } from "@fluentui/react";
import { SendRegular } from "@fluentui/react-icons";
import Send from "../../assets/Send.svg";
import styles from "./QuestionInput.module.css";
import { appStateReducer } from "../../state/AppReducer";
import { AppStateContext } from "../../state/AppProvider";
import { useContext, ChangeEvent, DragEvent } from "react";
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';


interface Props {
    onSend: (question: string, files?: File[], id?: string) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    conversationId?: string;
}

export const QuestionInput = ({ onSend, disabled, placeholder, clearOnSend, conversationId }: Props) => {
    const [question, setQuestion] = useState<string>("");
    const appStateContext = useContext(AppStateContext)
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [loadedFiles, setLoadedFiles] = useState<File[]>([]);


    const onFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            // Check if files in files array are not already in loadedFiles array
            const newFiles = Array.from(files).filter((file) => !loadedFiles.includes(file));
            setLoadedFiles([...loadedFiles, ...newFiles]);
            let fileCount = loadedFiles.length + newFiles.length;
            appStateContext?.dispatch({ type: 'FILE_LOADED', payload: fileCount });
        }
    };
    
    const onDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };
    
    const onDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (files && files.length > 0) {
            // Check if files in files array are not already in loadedFiles array
            const newFiles = Array.from(files).filter((file) => !loadedFiles.includes(file));
            setLoadedFiles([...loadedFiles, ...newFiles]);
            let fileCount = loadedFiles.length + newFiles.length;
            appStateContext?.dispatch({ type: 'FILE_LOADED', payload: fileCount });
        }
    };


    const sendQuestion = () => {
        if (disabled || !question.trim() && !loadedFiles) {
            return;
        }
    
        if(conversationId){
            if(loadedFiles){
                onSend(question, loadedFiles, conversationId);
            }else{
                onSend(question, undefined, conversationId);
            }
        }else{
            if(loadedFiles){
                onSend(question, loadedFiles);
            }else{
                onSend(question);
            }
        }
    
        if (clearOnSend) {
            setQuestion("");
            setLoadedFiles([]);
            appStateContext?.dispatch({ type: 'FILE_LOADED', payload: 0 });
            appStateContext?.dispatch({ type: 'SET_LOADED_QUESTION', payload: null });
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey && !(ev.nativeEvent?.isComposing === true)) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setQuestion(newValue || "");
        appStateContext?.dispatch({ type: 'SET_LOADED_QUESTION', payload: null });
    };

    useEffect(() => {
    const loadedQuestion = appStateContext?.state.loadedQuestion;
    if (loadedQuestion) {
        setQuestion(loadedQuestion);
    }
    }, [appStateContext?.state.loadedQuestion]);

    const sendQuestionDisabled = disabled || (!question.trim() && loadedFiles.length === 0);

    return (
        <Stack horizontal className={appStateContext?.state.deviceType?.isDesktop ? styles.questionInputContainer : styles.questionInputContainerMobile}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '93%' }}>
                {appStateContext?.state.deviceType?.isDesktop === true && (
                    <>
                        <input ref={fileInputRef} type="file" style={{ display: 'none' }} multiple onChange={onFileInputChange} />
                        <div
                            className={appStateContext?.state.fileLoaded > 0 ? styles.fileDropzoneUpload : styles.fileDropzone}
                            onDragOver={onDragOver}
                            onDrop={onDrop}
                            onClick={() => fileInputRef.current?.click()}
                        >
                            <FontAwesomeIcon icon={faPaperclip} style={{ fontSize: '50px' }} />
                            {appStateContext?.state.fileLoaded > 0 ? <sub>{appStateContext?.state.fileLoaded}</sub> : null}
                        </div>
                    </>
                )}
                <TextField
                    className={appStateContext?.state.deviceType?.isDesktop ? styles.questionInputTextArea : styles.questionInputTextAreaMobile}
                    placeholder={placeholder}
                    multiline
                    resizable={false}
                    borderless
                    value={question}
                    onChange={onQuestionChange}
                    onKeyDown={onEnterPress}
                /> 
            </div>
            <div className={styles.questionInputSendButtonContainer} 
                role="button" 
                tabIndex={0}
                aria-label="Ask question button"
                onClick={sendQuestion}
                onKeyDown={e => e.key === "Enter" || e.key === " " ? sendQuestion() : null}
            >
                { sendQuestionDisabled ? 
                    <SendRegular className={styles.questionInputSendButtonDisabled}/>
                    :
                    <img src={Send} className={styles.questionInputSendButton}/>
                }
            </div>
        </Stack>
    );
};
